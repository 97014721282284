import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState, } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { clamp } from '@src/shared/lib/Helpers';
import AnimatedPie from '@shared/ui/charts/AnimatedPie';
import OverallWalletsCount from './components/OverallWalletsCount';
import ActiveBalancePieDetails from './components/ActiveBalancePieDetails';
import EmptyWallets from './components/EmptyWallets';
import ActiveBalancePieError from './components/ActiveBalancePieError';
const ChartContainer = styled.div(() => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const DetailsContainer = styled.div(() => ({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
}));
const getPrimaryColors = (theme) => [
    theme.customColors.graphs.pie_chart.pie_color.primary[0],
    theme.customColors.graphs.pie_chart.pie_color.primary[1],
    theme.customColors.graphs.pie_chart.pie_color.primary[2],
    theme.customColors.graphs.pie_chart.pie_color.primary[3],
];
const getSecondaryColors = (theme) => [
    theme.customColors.graphs.pie_chart.pie_color.secondary[0],
    theme.customColors.graphs.pie_chart.pie_color.secondary[1],
    theme.customColors.graphs.pie_chart.pie_color.secondary[2],
    theme.customColors.graphs.pie_chart.pie_color.secondary[3],
];
const BalancePieChart = ({ width, height, data, priorityWalletType, onHover = () => { }, handleExchangeConnect = undefined, hasErrors = false, }) => {
    const [activeBalancePie, setActiveBalancePie] = useState();
    const theme = useTheme();
    const primaryColors = useMemo(() => getPrimaryColors(theme), [theme]);
    const secondaryColors = useMemo(() => getSecondaryColors(theme), [theme]);
    const errorColor = '#F9C6C6';
    const emptyColor = theme.customColors.button.secondary.color;
    const colors = useMemo(() => {
        const map = {};
        const primary = [...primaryColors];
        const secondary = [...secondaryColors];
        data.forEach((wallet) => {
            if (wallet.wallet_type === priorityWalletType) {
                map[wallet.id] = primary.pop() || '';
            }
            else {
                map[wallet.id] = secondary.pop() || '';
            }
        });
        return map;
    }, [
        data,
        priorityWalletType,
        primaryColors,
        secondaryColors,
    ]);
    const isDataEmpty = data.length === 0;
    const totalBalance = data.reduce((sum, d) => sum + parseFloat(d.totalAssetsUsd), 0);
    const getPieKey = (d) => d.id;
    const getPieValue = (d) => clamp(parseFloat(d.totalAssetsUsd), (totalBalance * 2.5) / 100, // NOTE: 2.5% of totalBalance
    totalBalance);
    const getPieColor = useCallback((d) => {
        if (hasErrors) {
            return errorColor;
        }
        if (isDataEmpty) {
            return emptyColor;
        }
        return colors[d.id];
    }, [
        isDataEmpty,
        hasErrors,
        colors,
        errorColor,
        emptyColor,
    ]);
    const pieData = useMemo(() => {
        if (isDataEmpty || hasErrors) {
            return [{
                    assets: [],
                    id: '1',
                    externalFinanceAccountId: '2',
                    provider: 'BINANCE',
                    totalAssetsUsd: '0.00',
                    totalAssetsUsdt: '0.00',
                    type: 'SPOT',
                    wallet_type: 'wallet',
                    balance_diff_in_percentage: 0,
                    balance_diff_in_currency: 0,
                    exchange: {
                        id: '0',
                        type: 'BINANCE',
                        totalBalance: 100,
                        properties: {
                            apiKey: '',
                            secretKey: '',
                            connectionName: '',
                        },
                        userId: '1',
                    },
                }];
        }
        return data;
    }, [isDataEmpty, hasErrors, data]);
    const onPieArcHover = (d, isHovered) => {
        setActiveBalancePie(isHovered ? d : undefined);
        onHover(d, isHovered);
    };
    const pieSort = useCallback((a, b) => {
        const sortByBalanceResult = Number(b.totalAssetsUsd) - Number(a.totalAssetsUsd);
        if (a.wallet_type === b.wallet_type) {
            return sortByBalanceResult;
        }
        // priority type stays first
        return a.wallet_type === priorityWalletType ? -1 : 1;
    }, [priorityWalletType]);
    return (_jsxs(ChartContainer, { style: {
            width,
            height,
        }, children: [_jsx(AnimatedPie, { width: width, height: height, data: pieData, getKey: getPieKey, pieValue: getPieValue, getColor: getPieColor, activeArc: activeBalancePie, onArcHover: onPieArcHover, pieSort: pieSort, disabled: hasErrors || isDataEmpty }), _jsxs(DetailsContainer, { style: {
                    width: width / 2,
                    height: height / 2,
                }, children: [hasErrors && (_jsx(ActiveBalancePieError, { onUpdateApiKeyClick: () => { } })), !hasErrors &&
                        isDataEmpty && (_jsx(EmptyWallets, { onConnectClick: handleExchangeConnect })), !hasErrors &&
                        !isDataEmpty &&
                        !activeBalancePie && (_jsx(OverallWalletsCount, { wallets: data, priorityWalletType: priorityWalletType })), !hasErrors &&
                        !isDataEmpty &&
                        !!activeBalancePie && (_jsx(ActiveBalancePieDetails, { balancePie: activeBalancePie }))] })] }));
};
export default BalancePieChart;
