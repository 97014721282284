export var PaymentMethodFormType;
(function (PaymentMethodFormType) {
    PaymentMethodFormType["AddPaymentMethod"] = "ADD_PAYMENT_METHOD";
    PaymentMethodFormType["EditPaymentMethod"] = "EDIT_PAYMENT_METHOD";
})(PaymentMethodFormType || (PaymentMethodFormType = {}));
export var ActivityType;
(function (ActivityType) {
    ActivityType["CreatePortflio"] = "CREATE_PORTFOLIO";
    ActivityType["ClosePortfolio"] = "CLOSE_PORTFOLIO";
    ActivityType["StartFollowing"] = "START_FOLLOWING";
    ActivityType["StopFollowing"] = "STOP_FOLLOWING";
})(ActivityType || (ActivityType = {}));
