const PAYMENT_METHOD_INIT_FIELDS = {
    holderName: '',
    cardNumber: '',
    expireDate: '00/00',
    cvv: '',
    paymentSystem: null,
};
const PAYMENT_METHOD_EDIT_FIELDS = {
    holderName: 'Tom Shelby',
    cardNumber: '1234 5678 9123 4567',
    expireDate: '00/00',
    cvv: '123',
    paymentSystem: null,
};
export { PAYMENT_METHOD_INIT_FIELDS, PAYMENT_METHOD_EDIT_FIELDS, };
