const INIT_VALUES = {
    clientProfile: undefined,
    traderProfile: undefined,
};
// eslint-disable-next-line import/prefer-default-export
export const defineProfiles = (profiles) => (profiles === null || profiles === void 0 ? void 0 : profiles.reduce((acc, profile) => {
    if (profile.type === 'CLIENT') {
        acc.clientProfile = profile;
    }
    else if (profile.type === 'TRADER') {
        acc.traderProfile = profile;
    }
    return acc;
}, INIT_VALUES)) || INIT_VALUES;
